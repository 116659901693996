import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OktaService } from "src/app/factory/okta.service";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ClientModel } from "projects/filter/src/lib/modals/client-model";
import { IReportDetailsResponse } from "./models/report-details-response";

@Injectable({
  providedIn: "root",
})
export class DbaasApiService {
  apiURL: string = environment.DBAAS_API_URL;
  client: ClientModel;

  /* private _reportConfigCache: any;

  public get reportConfigCache(): any {
    return this._reportConfigCache;
  }
  public set reportConfigCache(value: any) {
    this._reportConfigCache = value;
  } */

  private _reportData: any;
  public get reportData(): any {
    return this._reportData;
  }
  public set reportData(value: any) {
    this._reportData = value;
  }
  constructor(private http: HttpClient, private oktaService: OktaService) {
    this.oktaService.client$.subscribe((client) => {
      this.client = client;
    });
  }

  generateCorrelationId(): string {
    let uuid = self.crypto.randomUUID();
    return uuid;
  }

  getEmbedToken(body) {
    const token = this.oktaService.getAccessToken();
    const headers = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.post(
      this.apiURL + "/v1/PowerBIService/GetEmbedToken",
      body,
      headers
    );
  }

  async getReportData(uiReportId, pageName): Promise<IReportDetailsResponse> {
    const token = this.oktaService.getAccessToken();
    const headers = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    const body = {
      uiReportId: uiReportId,
      clientName: this.client.clientName,
      pageName: pageName,
    };
    var response = await this.http
      .post(
        this.apiURL + "/v1/PowerBIService/GetReportInfoByUIReportId",
        body,
        headers
      )
      .toPromise();
    return response as IReportDetailsResponse;
  }

  getReportDataV2(uiReportId, pageName): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    const body = {
      uiReportId: uiReportId,
      clientName: this.client.clientName,
      pageName: pageName,
    };
      return this.http
      .post(
        this.apiURL + "/v2/PowerBIService/GetReportInfoByUIReportId",
        body,
        headers
      );
  }

  getWorkspaceReports(workspaceId): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(
      this.apiURL +
        "/v1/PowerBIService/GetAdminReportsByGroupId?groupId=" +
        workspaceId,
      {
        headers: headers,
      }
    );
  }

  getWorkspaces(): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(this.apiURL + "/v1/PowerBIService/GetWorkspaces", {
      headers: headers,
    });
  }

  getAllClients(): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(this.apiURL + "/v1/ReportsInfo/GetAllClients", {
      headers: headers,
    });
  }

  getReportWorkspaces(reportName): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(
      this.apiURL +
        "/v1/ReportsInfo/GetWorkspaceNames?reportName=" +
        reportName,
      {
        headers: headers,
      }
    );
  }
  getAllReports(): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(this.apiURL + "/v1/ReportsInfo/GetAllReports", {
      headers: headers,
    });
  }

  addorEditReport(reportData): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL + "/v1/ReportsInfo/AddorUpdateReport",
      reportData,
      {
        headers: headers,
      }
    );
  }

  getAllClientsAssociatedToReport(reportName: string): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.get(
      this.apiURL +
        "/v1/ReportsInfo/GetAllClientsAssociatedToReport?reportName=" +
        reportName,
      {
        headers: headers,
      }
    );
  }

  addClientToReport(reportData): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL + "/v1/ReportsInfo/AddClientAndWorkspaceInfoToReport",
      reportData,
      {
        headers: headers,
      }
    );
  }

  deleteReportWorkspace(id: string): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.delete(
      this.apiURL + "/v1/ReportsInfo/DeleteReportWorkspace?Id=" + id,
      { headers: headers }
    );
  }

  deleteReport(uiReportId: string): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.delete(
      this.apiURL + "/v1/ReportsInfo/DeleteReport?UIReportId=" + uiReportId,
      { headers: headers }
    );
  }

  saveCustomView(requestBody): Observable<any> {
    requestBody.clientName = this.client?.clientName;
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL + "/v1/PowerBIService/dashboardcustomviews",
      requestBody,
      {
        headers: headers,
      }
    );
  }

  getCustomViews(reportId: number) {
    const token = this.oktaService.getAccessToken();
    const headers = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };
    return this.http.get(
      this.apiURL + `/v1/PowerBIService/dashboardcustomview/${this.client.clientName}/${reportId}`,
      headers
    );
  }

  deleteCustomView(id: string, reportId: number): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.delete(
      this.apiURL + `/v1/PowerBIService/dashboardcustomview/${this.client.clientName}/${reportId}/${id}`,
      { headers: headers }
    );
  }

  updateCustomView(requestBody): Observable<any> {
    requestBody.clientName = this.client?.clientName;
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL + "/v1/PowerBIService/dashboardcustomview",
      requestBody,
      {
        headers: headers,
      }
    );
  }
  setDefaultView(requestBody): Observable<any> {
    requestBody.clientName = this.client?.clientName;
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL + "/v1/PowerBIService/dashboardcustomviewsetdefaultview",
      requestBody,
      {
        headers: headers,
      }
    );
  }
  updateCustomViewName(requestBody): Observable<any> {
    requestBody.clientName = this.client?.clientName;
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL +
        "/v1/PowerBIService/dashboardcustomviewsetdefaultandcustomviewname",
      requestBody,
      {
        headers: headers,
      }
    );
  }
  updateCustomViewBookmark(requestBody): Observable<any> {
    requestBody.clientName = this.client?.clientName;
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return this.http.post(
      this.apiURL +
        "/v1/PowerBIService/dashboardcustomviewsetcutomviewbookmark",
      requestBody,
      {
        headers: headers,
      }
    );
  }

  getAllUserDashboardFavorites(): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders()
      .set("Authorization", `Bearer ${token}`)
      .set("X-Correlation-ID", this.generateCorrelationId());
    return this.http.get(
      this.apiURL + `/v1/Dashboard/Favorite/${this.client.clientName}`,
      { headers: headers }
    );
  }

  getUserDashboardFavorite(reportId: Number): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders()
      .set("Authorization", `Bearer ${token}`)
      .set("X-Correlation-ID", this.generateCorrelationId());
    return this.http.get(
      this.apiURL + `/v1/Dashboard/Favorite/${this.client.clientName}/` + reportId,
      { headers: headers }
    );
  }

  setUserDashboardFavorite(reportId: Number, setAsFavorite: boolean): Observable<any> {
    const token = this.oktaService.getAccessToken();
    const headers = new HttpHeaders()
      .set("Authorization", `Bearer ${token}`)
      .set("X-Correlation-ID", this.generateCorrelationId());
    return this.http.post(
      this.apiURL + `/v1/Dashboard/Favorite/${this.client.clientName}`,
      {
        dashboardId: reportId,
        setAsFavorite
      },
      { headers }
    );
  }
}
