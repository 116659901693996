
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<mat-toolbar class="top-nav">

    <!-- optional route nav appears if not a handset and topNavTabRoutes has items -->
    <div *ngIf="(!(isHandset | async)?.matches) && (menuService.isMenuLoaded$| async) && (menuService.topNavTabRoutes$ | async)" class="top-nav-list-wrapper" >
        <span>
        <app-local-io-rout-tab-nav-bar
            [ioRoutList]="menuService.topNavTabRoutes">
        </app-local-io-rout-tab-nav-bar>
        </span>
    </div>

    <span class="toolbar-spacer"></span>
    <div style="position: relative; display: flex;">
        <div class="notification-count" *ngIf="(availableCount$ | async) > 0">{{(availableCount$ | async)}}</div>
        <!-- Saved for debugging <div>
          <span>
            {{currentRouteIsPbiDashboard ? 'PBI' : '___'}}
          </span>
          <span>
            {{currentDashboardIsFavorite ? 'FAV' : '___'}}
          </span>
          <span>
            PREV:{{previousDashboardId}}
          </span>
          <span>
            CURR:{{currentDashboardId}}
          </span>
        </div> -->
        <nav mat-tab-nav-bar>
            <div *ngIf="currentRouteIsPbiDashboard" [@contractExpand]="apiCallState">
                <span (click)="setUserDashboardFavorite(true)" [style.display]="!currentDashboardIsFavorite ? 'block' : 'none'" width="15px" height="15px" class="top-bar__tools-favorite-icon top-bar__tools-favorite-icon-empty"></span>
                <span (click)="setUserDashboardFavorite(false)" [style.display]="currentDashboardIsFavorite ? 'block' : 'none'" width="15px" height="15px" class="top-bar__tools-favorite-icon top-bar__tools-favorite-icon-filled"></span>
            </div>
            <a mat-tab-link [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger">
                My Downloads
                <mat-icon [ngClass]="{'flipped': menuTrigger.menuOpen}">expand_more</mat-icon>
            </a>
        </nav>
    </div>

    <span class="application-menu-title" (click)="toggleLoggerServiceDebug()"> {{applicationMenuTitle}} </span>
</mat-toolbar>

<mat-menu #menu="matMenu" class="download-menu">
    <app-my-downloads-card></app-my-downloads-card>
</mat-menu>
