
<div style="padding: 50px; height: calc(100% - 102px);">
    <div class="heading-text">
        <span class="bold-text" *ngIf="userInfo?.name">{{userInfo.name}},</span> welcome and happy dashboarding!
    </div>
    <br>
    <div class="welcome-body-text">
        <p>
            <span class="bold-text">Explore </span>
            <br>
            <span>Browse dashboards, reports, and tools from the navigation menu on the left.</span>
        </p>
        <p>
            <span class="bold-text">Learn </span>
            <br>
            For help with your current task, click the Knowledge Bot lightbulb at the bottom of the screen or visit the
            <a href="https://support.inovalon.com/help_center" target="_blank">Help Center</a> for guides, FAQs, and more.
        </p>
        <span class="bold-text">Customize</span>
        <ul>
            <li>To hide the navigation, click the &lt; icon.</li>
            <li>To show the navigation all the time, check Keep Open.</li>
            <li>Drag the Knowledge Bot lightbulb to where you'd prefer it on the screen.</li>
        </ul>
    </div>
    <div *ngIf="userDashboardFavorites?.length" class="heading-text">
        <span class="bold-text">Favorites</span>
    </div>
    <div *ngIf="userDashboardFavorites?.length" class="welcome-body-text">
      <ul>
        <li *ngFor="let udf of userDashboardFavorites">
          <a href="javascript:void(0);" (click)="navigateToUserDashboardFavorite(udf.routerLink)">{{udf.caption}}</a>
        </li>
      </ul>
    </div>
</div>
