
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { Injectable } from '@angular/core';
import { IndicesMenuApiService } from './indices-menu-api.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { TokenModel } from '../models/token.model';
import { OktaService } from '../factory/okta.service';
import { ClientModel } from '../models/client.model';
import { LoggerService } from '../shared/services/logger.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '@io-angular/auth';

@Injectable({
  providedIn: 'root'
})
export class IndicesUserActivityService {
  private cache: { [id: number]: IndicesUserActivity } = {};
  private browser: string;
  private client: ClientModel;
  constructor(private indicesMenuApiService: IndicesMenuApiService,
    private router: Router,
    private tokenModel: TokenModel,
    private oktaService: OktaService,
    private loggerService: LoggerService,
    private authService:AuthService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.startTracking(event.id, event.url);
      }
      if (event instanceof NavigationEnd) {
        this.stopTracking(event.id);
      }
    });
    this.browser = this.getBrowserVersion();
    this.oktaService.client$.subscribe((client) => {
      this.client = client;
    });
  }

  startTracking(id: number, url: string) {
    this.cache[id] = {
      userName: this.tokenModel.upn,
      navigation: url,
      activityDateTime: new Date(),
      time: new Date().getTime()
    };
  }

  public async stopTracking(id: number) {
    const data = this.cache[id];
    // const userClaims = await firstValueFrom(this.authService.isAuthenticated$);
    if (!data.userName || data.userName === '') {
      const isAuthenticated = await firstValueFrom(this.authService.isAuthenticated$);
      if (isAuthenticated) {
        // const user = await this.oktaService.oktaAuthService.getUser();
        // data.userName = user ? user.preferred_username : null;
        data.userName = this.authService.upn ? this.authService.upn: this.authService.emailAddress;
      }
    }
    if (data) {
      if (data.userName && data.userName !== '') {
        const elapsed = new Date().getTime() - data.time;
        data.clientName = this.client ? this.client.clientName.toLowerCase() : null;
        data.browserType = this.browser;
        data.DurationMs = elapsed;
        data.TotalSeconds = Math.round(elapsed / 1000);
        data.url = window.location.href;
        data.domain = 'INDICES 3.0';
        this.getContentType(data);
        this.logUserActivity(data);
      }
      delete this.cache[id];
    }
  }

  logUserActivity(activity: IndicesUserActivity) {
    const navUrl = `api/User/LogActivity`;
    if (activity.navigation === '/logout') {
      return false;
    }
    this.indicesMenuApiService.post('IndicesUserActivityService.logUserActivity', navUrl, activity).subscribe({
      error: (err) => this.loggerService.logger({
        type: 'hidden-error',
        class: 'IndicesUserActivityService',
        method: 'logUserActivity',
        message: `Error calling IndicesUserActivityService.`,
        error: err
      })
    });
  }

  getBrowserVersion() {
    const userAgent = navigator.userAgent;
    let tem;
    let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(matchTest[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
      return 'IE ' + (tem[1] || '');
    }

    if (matchTest[1] === 'Chrome') {
      tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
      if (tem != null) { return tem.slice(1).join(' ').replace('OPR', 'Opera'); }
    }

    matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = userAgent.match(/version\/(\d+)/i);
    if (tem != null) { matchTest.splice(1, 1, tem[1]); }
    return matchTest.join(' ');

  }

  getContentType(data) {
    const content = (data.navigation.substring(data.navigation.lastIndexOf('/') + 1));
    if (content === '') {
      data.contentType = 'Reporting';
      data.contentName = 'INDICES 3.0 Landing';
    } else if (content.toLowerCase() === 'logout') {
      data.contentType = '';
      data.contentName = '';
    } else {
      data.contentType = 'Reporting';
      data.contentName = content.charAt(0).toUpperCase() + content.slice(1).replace(/([A-Z])/g, ' $1').trim();
    }
  }
}

export interface IndicesUserActivity {
  userName?: string;
  clientName?: string;
  activityDateTime: Date;
  browserType?: string;
  domain?: string;
  contentType?: string;
  contentName?: string;
  url?: string;
  navigation: string;
  description?: string;
  DurationMs?: number;
  TotalSeconds?: number;
  time: number;
}
