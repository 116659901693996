
/*
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
*/

import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { MenuService } from 'src/app/services/menu.service';
import { NavigationStart, Router } from '@angular/router';
import { LoggerService } from 'src/app/shared/services/logger.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { DownloadFileService } from 'src/app/services/download-file.service';
import { MenuConfigConstants } from 'src/app/shared/constants/menu-config';
import { MatMenuTrigger } from '@angular/material/menu';
import { DbaasApiService } from 'src/app/dbaas/dbaas-shared/dbaas-services/dbaas-api.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css'],
  animations: [
    trigger('contractExpand', [
      state('idle', style({ opacity: 1 })),
      state('loading', style({ opacity: 0.3 })),
      transition('idle => loading', [
        animate('500ms ease-in') // Contract slowly
      ]),
      transition('loading => idle', [
        animate('300ms ease-out') // Expand faster
      ]),
    ]),
  ]
})
export class TopNavComponent implements OnInit, OnDestroy {
  @Input() applicationTitle: string;
  @ViewChild('menuTrigger', { static: false }) trigger: MatMenuTrigger;
  isHandset: Observable<BreakpointState>;
  destroyer$: Subject<null> = new Subject()
  // availableCount$:Observable<number> = null
  applicationMenuTitle: string;
  currentRouteIsPbiDashboard: boolean = false;
  currentDashboardIsFavorite: boolean = false;
  currentDashboardId = 0;
  previousDashboardId = 0;
  apiCallState: 'idle' | 'loading' = 'idle';
  apiCallInProgress = false;

  private _availableCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  availableCount$: Observable<number> = this._availableCount$.asObservable()

  constructor(
    private breakpointObserver: BreakpointObserver,
    public menuService: MenuService,
    public router: Router,
    private loggerService: LoggerService,
    private downloadFileService: DownloadFileService,
    private dbaasApiService: DbaasApiService,
  ) {
    this.isHandset = this.breakpointObserver.observe('(max-width: 839px)');

    this.availableCount$ = this.downloadFileService.availableCount$
    this.availableCount$.subscribe(val => {
    })

    this.menuService.routeUrl$.subscribe((routeUrl) => {
      if (routeUrl) {
        this.refreshUserDashboardFavoriteIcon(routeUrl);
        switch (routeUrl.split("/")[0]) {
          case "mi":
            this.applicationMenuTitle =
              MenuConfigConstants.ConvergedDataIngestion;
            break;
          case "qpi":
            this.applicationMenuTitle = MenuConfigConstants.ConvergedQuality;
            break;
          case "rli":
            const subMenu = routeUrl.split("/")[1];
            if (subMenu && subMenu === "oi") {
              this.applicationMenuTitle = MenuConfigConstants.ConvergedRisk;
            } else {
              this.applicationMenuTitle = MenuConfigConstants.DataInsights;
            }

            break;
          case "vbpi":
            this.applicationMenuTitle = MenuConfigConstants.ProviderEnablement;
            break;
          default:
            this.applicationMenuTitle = MenuConfigConstants.DataInsights;
            break;
        }
      } else {
        this.applicationMenuTitle = MenuConfigConstants.DataInsights;
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((value: NavigationStart) => {
      if (!value ||!value.url) return;
      if (['imperative','popstate'].includes(value.url)) {
        this.refreshUserDashboardFavoriteIcon(value.url);
      }
    });
    this.router.events.pipe(takeUntil(this.destroyer$)).subscribe(val => {
      if (val instanceof NavigationStart && this.trigger) {
        this.trigger.closeMenu()
        this.refreshUserDashboardFavoriteIcon(val.url);
      }
    })
  }

  private getDBaaSDashboardId(url: string) {
    const matches = /\/dbaas\/report\/(\d+)/.exec(url)
    if (matches.length == 2) {
      return Number(matches[1]);
    }
    return null;
  }

  private refreshUserDashboardFavoriteIcon(url: string) {
    if (this.apiCallInProgress) {
      return; // Exit if API call is already in progress
    }

    if (!url.startsWith('/')) url = '/' + url;
    if (url.startsWith('/dbaas/report/')) {
      const newReportId = this.getDBaaSDashboardId(url);
      if (newReportId) {
        this.currentDashboardId = newReportId;
        if (newReportId !== this.previousDashboardId) {
          this.apiCallInProgress = true;
          this.dbaasApiService.getUserDashboardFavorite(newReportId).pipe( finalize(() => {
            this.apiCallInProgress = false; // Reset the flag when the call is finished
          })).subscribe({
            next: () => {
              this.currentRouteIsPbiDashboard = true;
              this.currentDashboardIsFavorite = true;
              this.previousDashboardId = newReportId;
            },
            error: (error) => {
              if (error.status === 404) {
                this.currentRouteIsPbiDashboard = true;
                this.currentDashboardIsFavorite = false;
                return;
              }
              this.loggerService.logger({
                type: "error",
                class: "TopNavComponent",
                method: "checkIfDashboardIsFavorite",
                message: "couldn't get the data",
                error,
              });
            },
          });
          return;
        } else {
          this.apiCallInProgress = false;
        }
      }
      return;
    }
    this.currentRouteIsPbiDashboard = false;
    this.currentDashboardIsFavorite = false;
    this.currentDashboardId = 0;
    this.previousDashboardId = 0;
  }

  toggleLoggerServiceDebug() {
    this.loggerService.isDebug = !this.loggerService.isDebug;
  }

  setUserDashboardFavorite(setAsFavorite: boolean) {
    if (this.apiCallState === 'loading') {
      return; // Prevent multiple clicks
    }

    this.apiCallState = 'loading';
    this.apiCallInProgress = true;

    this.dbaasApiService.setUserDashboardFavorite(this.currentDashboardId, setAsFavorite).subscribe(
      (response) => {
        this.currentDashboardIsFavorite = setAsFavorite;
      },
      (error) => {
        if (error.status === 404) {
          this.currentDashboardIsFavorite = false;
          return;
        }
        this.loggerService.logger({
          type: "error",
          class: "TopNavComponent",
          method: "onAddFavoriteClick",
          message: "couldn't update the data",
          error,
        });
      },
      () => {
        this.apiCallState = 'idle';
        this.apiCallInProgress = false;
      }
    );
  }

  ngOnDestroy() {
    this.destroyer$.next(null)
    this.destroyer$.complete()
  }

}
