
<!--
* Copyright (c) 2019 – 2020, INOVALON, INC. All Rights Reserved.
* This computer program is CONFIDENTIAL and a TRADE SECRET of Inovalon, Inc.
* The receipt or possession of this program does not convey any rights to use,
* reproduce or disclose its contents in whole or in part, without the specific
* written consent of Inovalon, Inc. Any use, reproduction or disclosure of
* this program without the express written consent of Inovalon, Inc., is a
* violation of the copyright laws and may subject you to criminal prosecution.
-->

<ul class="primary-nav">
    <li *ngFor="let menuItem of dashboardMenu"
        [class.current]="getActiveMenu(menuItem.id)">
        <a (click)="menuItem.children ? setActiveMenu(menuItem.id, $event) : null">
            <app-svg-icon [name]="menuItem.icon">
            </app-svg-icon>
            <span class="link-text">
                {{menuItem.name}}
            </span>
        </a>
        <div *ngIf="menuItem.children && menuItem.children.length > 0">
           <ul class="flyout-nav"
                [style.display]="getActiveMenu(menuItem.id) ? 'block' : 'none'">
                <div class="flyout-nav-controls flyout-nav-controls--short">
                    <button class="flyout-nav__close-btn"
                            (click)="setActiveMenu(menuItem.id, $event)">
                            <app-svg-icon name="close_x"></app-svg-icon>
                    </button>
                </div>
                <li *ngFor="let secondLevel of menuItem.children"
                    [class.current-sub]="getActiveSubMenu(secondLevel.id)">
                    <a (click)="secondLevel.children ? setActiveSubMenu(secondLevel.id, $event) : null">
                        {{secondLevel.name}}
                    </a>
                    <div *ngIf="secondLevel.children && secondLevel.children.length > 0">
                        <ul class="flyout-nav"
                            [style.display]="getActiveSubMenu(secondLevel.id) ? 'block' : 'none'">
                            <div class="flyout-nav-controls flyout-nav-controls--short clearfix">
                                <button class="flyout-nav__close-btn"
                                        (click)="setActiveSubMenu(secondLevel.id, $event)">
                                        <app-svg-icon name="close_x"></app-svg-icon>
                                </button>
                            </div>
                            <li *ngFor="let thirdLevel of secondLevel.children">
                                <a [href]="getFormattedURL(thirdLevel.url)">
                                    {{thirdLevel.name}}
                                </a>
                                <!-- <a *ngIf="isValidIdp(); else elseBlock" [href]="thirdLevel.url.indexOf('?') > -1 ? thirdLevel.url +'&chkClt=1' : thirdLevel.url + '?chkClt=1'+ '&idp='+idp">
                                    {{thirdLevel.name}}
                                </a>                                -->
                                <!-- <ng-template #elseBlock>
                                    <a [href]="thirdLevel.url.indexOf('?') > -1 ? thirdLevel.url +'&chkClt=1' : thirdLevel.url + '?chkClt=1'">
                                        {{thirdLevel.name}}
                                    </a>
                                </ng-template> -->
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </li>
</ul>
